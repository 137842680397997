<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Satuan</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-check-square-o mg-r-5"></i>Data Master</a>
                <span class="breadcrumb-item active"> Data Satuan</span>
            </div>
        </div> 

        <!-- MODALS ================================================== -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <!-- Title -->
                        <h5 class="card-header-title">
                            Form Satuan
                        </h5>
                    </div>
                    <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Satuan<span class="text-danger">*</span></label>
                                <input required="" type="text" v-model="form.satuan" value="" class="form-control" placeholder="">
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal</button>
                                <button type="submit" class="btn btn-primary">Simpan</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Satuan...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                    <!-- HEADER -->
                                    <button v-if="userdata.id > 2914" class="btn btn-primary ml-2" @click="show_modal()">
                                        <div class="d-flex align-items-center">
                                            <i class="fe fe-plus mr-2"></i>Tambah Satuan
                                        </div>
                                    </button>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>Satuan</th>
                                            <th style="width:10%">Status</th>
                                            <th v-if="userdata.role == 'administrator'" style="width:10%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="4">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(item, index) in dataSatuan" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.satuan }}</td>
                                            <td>
                                                <badge v-if="item.status === '1'" class="badge badge-success">Disetujui</badge>
                                                <badge v-if="item.status === '0'" class="badge badge-warning">Pengajuan</badge>
                                            </td>
                                            
                                            <td v-if="userdata.role == 'administrator'" class="text-center">
                                                <button v-if="item.status == 0" @click.prevent="acceptForm(item.id)" class="btn btn-success btn-sm mr-2"><i class="fe fe-check mr-2"></i>Setujui</button>
                                                <button @click.prevent="editForm(item.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit mr-2"></i>Ubah</button>
                                                <button @click.prevent="postDelete(item.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2 mr-2"></i>Hapus</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
                <br><br><br>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vPagination from 'vue-plain-pagination'
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataSatuan: [],
                fetching: true,
                form: {
                    id: '',
                    satuan: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getData();
        },
        computed: mapState(['userdata']),
        components: {
            vPagination
        },
        methods: {
            show_modal() {
                this.showSelectYear = true;
                this.form.id = '';
                this.form.satuan = '';
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/satuan', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataSatuan = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            submitForm() {
                if (this.form.nama_pengutamaan == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Nama Pengutamaan tidak boleh kosong',
                    })
                } else {
                    this.$http.post(this.baseUrl + 'admin/master/satuan/save', this.form)
                        .then((response) => {
                            if(response.data.status){
                                this.$swal({
                                    icon: 'success',
                                    title: "success",
                                    text: 'Data Satuan berhasil disimpan',
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                            }else{
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            
                            this.$modal.hide('my-modal')
                            this.getData()
                            this.form.code = ''
                            this.form.satuan = ''
                        })
                        .catch(error => {
                            console.log(error.response.data)
                        });
                }
            },
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/satuan/edit?id=${id}`)
                    .then((response) => {
                        this.form.id = response.data.data[0].id;
                        this.form.satuan = response.data.data[0].satuan;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/satuan/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            this.$swal(
                                'Deleted!',
                                response.data.message,
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            acceptForm(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Satuan yang disetujui dapat digunakan untuk transaksi',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ya, Setujui!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/satuan/accept',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            this.$swal(
                                'success!',
                                response.data.message,
                                'success'
                            )
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>